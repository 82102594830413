









































































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppForm from '@/components/AppForm.vue';
import FieldTime from '@/components/FieldTime.vue';
import DELIVERY from '@/graphql/queries/Delivery.graphql';
import DELIVERY_LOCATIONS from '@/graphql/queries/DeliveryLocations.graphql';
import DELIVERY_CHANGE from '@/graphql/mutations/DeliveryChange.graphql';
import { required } from 'vuelidate/lib/validators';
import validationMessages from '@/utils/validationMessages';
import { CODES, DELIVERY_STATUSES } from '@/utils/constants';
import {
  Delivery,
  DeliveryChangeMutationVariables,
  DeliveryQueryVariables,
} from '@/types/schema';
import { GraphQLError } from 'graphql';
import { ApolloQueryResult } from 'apollo-client';

export default Vue.extend({
  name: 'DriverDeliveryChange',
  components: {
    AppMain,
    AppForm,
    FieldTime,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
      form: {
        location: '',
        appointmentTime: '',
        changeReason: '',
        changeNotes: '',
      },
      delivery: {} as Delivery,
      deliveries: [] as Delivery[],
    };
  },
  computed: {
    hasDelivery(): boolean {
      return this.delivery && !!this.delivery.id;
    },
    locations(): Delivery[] {
      const { location } = this.delivery;
      return this.deliveries.filter((loc) => loc.id !== location.id);
    },
    reasons() {
      return ['Temperature', 'Wash tag', 'Seals', 'Driver attitude', 'Other'];
    },
  },
  validations() {
    return {
      form: {
        location: { required },
        appointmentTime: { required },
        changeReason: { required },
        changeNotes: { required },
      },
    };
  },
  methods: {
    validationMessages,
    async changeDelivery() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;
        this.errors = [];
        await this.$apollo.mutate({
          mutation: DELIVERY_CHANGE,
          variables: {
            input: {
              id: this.delivery.id,
              locationId: this.form.location,
              appointmentTime: this.form.appointmentTime,
              changeReason: this.form.changeReason,
              changeNotes: this.form.changeNotes,
            },
          } as DeliveryChangeMutationVariables,
        });
        this.$router.push({ name: 'driver-delivery' });
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
  },
  apollo: {
    delivery: {
      query: DELIVERY,
      variables(): DeliveryQueryVariables {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }: ApolloQueryResult<{ delivery: Delivery }>) {
        if (!data || !data.delivery) return;
        const { status, locationChanged } = data.delivery;
        const completed = status === DELIVERY_STATUSES.COMPLETED;
        if (completed || locationChanged) {
          this.$router.replace({ name: 'driver-delivery' });
        }
      },
      error({ graphQLErrors }) {
        const [gqlError] = graphQLErrors;
        if (gqlError?.extensions?.code === CODES.NOT_FOUND) {
          this.$router.replace({ name: 'driver-delivery' });
        }
      },
    },
    deliveries: {
      query: DELIVERY_LOCATIONS,
    },
  },
});
